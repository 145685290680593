<template>
    <b-modal id="action-Plan" @hidden="$emit('resetModal')" :title="selectedCity ? 'Update City' : 'Add City'"
      hide-footer>
       
 
      <div>
        <mapPathView v-on:onRemove="removeItem" :showPolyLine="true" :polylinePaths="selectedCity ? [polylineData] : [polylineData]" :customHeight="500"
        v-on:locationChange="locationChange" :zoom="5" :center="center" :markerLocations="selectedCity ? values.boundary_coordinates : values.boundary_coordinates" />
    </div>
    <br>
  
    <!-- <div>
      <mapView v-if="drawing" v-on:locationChange="locationChange" :markerLocations="markers" :center="initialPosition"
        :zoom="5" />
    </div> -->
    <br>
    <!-- <button class="btn bseen-btn btn-secondary" v-if="selectedCity" @click="toggleDrawing">Start Drawing</button> -->
      <FormulateForm name="PlanAction" v-model="values" :schema="schema" @submit="submitHandler">
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span v-if="selectedCity"> Updating... </span>
            <span v-else> Adding... </span>
          </span>
          <span v-else>
            <span v-if="selectedCity"> Update </span>
            <span v-else> Add </span>
            City</span>
        </FormulateInput>
      </FormulateForm>
    </b-modal>
  </template>
  
  <script>
  import { cities } from "@/config/api/cities";
  import mapPathView from "@/components/maps/mapPathView";
  // import mapView from "@/components/maps/mapView";

  export default {
    components: {
    mapPathView,
    // mapView,
  },

    props: ["selectedCity", "regionData", "drawing"],
    data() {
      return {
        polylineData: [],
        markers: [],
         boundary_coordinates: [], 
        values: {
          boundaryCoordinates: [], 
        },

        center: { lat: 25.190074301612864, lng: 47.1520809379568 },
        firstcoordinate: {},
        performAction: false,
        schema: [
          {
            index: 0,
            type: "text",
            name: "name",
            label: "Name",
            validation: "required",
          },
          {
            index: 1,
            type: "textarea",
            name: 'boundaryCoordinates',
            label: "Boundary Coordinates",
            validation: "required",
            placeholder: "Enter coordinates",
            validationMessages: {
                  required: "Boundary Coordinates is required",
                },
            help: "Enter a pair of coordinates ex: [[47.24683801803494,25.14707733795921],[47.34296839023918,24.93680785517937],[47.10538904179157,24.696238150015468],[47.213879032993646,24.450202586346926]]"
          },
          {
            index: 2,
            type: "select",
            name: "region",
            label: "Select Region",
            options: [],
            validation: "required",
            value: this.selectedCity ? this.selectedCity.region.name : "",
          },
          {
            index: 3,
            type: "number",
            name: "rank",
            label: "Rank",
            validation: "required",
          },
        ],
      };
    },
    created() {
      console.log("sdFSDFDFSDFSF");
  // Initialize the schema options and default value when the component is created
  this.updateSchemaOptions(this.selectedCity, this.regionData);
},
       mounted() {
        this.values.boundaryCoordinates = JSON.stringify(this.boundary_coordinates);
        console.log("boundary", this.values.boundaryCoordinates);
    },
    


    methods: {
      removeItem(ele) {
      const lat = ele.lat;
      const lng = ele.lng;
      this.polylineData=this.polylineData.filter((element)=>!(element.lat==lat && element.lng==lng))
     
      this.markers=this.markers.filter((element)=>!(element.position.lat==lat && element.position.lng==lng))
      this.values.boundary_coordinates = this.markers.map(item => ({
        lat: item.position.lat,
        lng: item.position.lng
      }));
      console.log(ele, "elel")
    },

      locationChange(val) {
      const lat = val.lat;
      const lng = val.lng;

    this.markers.push({ position: { lat, lng } });
    this.polylineData.push({ lat, lng });
     // const newMarker = { position: { lat, lng } };
    //  this.markers.push(newMarker);

      //onsole.log("newMarker", newMarker);

      this.values.boundary_coordinates = this.markers.map(item => ({
        lat: item.position.lat,
        lng: item.position.lng
      }));

      this.values.boundaryCoordinates = JSON.stringify(this.values.boundary_coordinates.map(item => [item.lng, item.lat]));
      console.log("bccccc", this.values.boundary_coordinates.map(item => [item.lat, item.lng]))
      console.log("bcc", this.values.boundaryCoordinates);

      // You can also pan the map to the new marker if needed
      this.panToMarker({ lat, lng });
    },
      toggleDrawing() {
      this.drawing = true;
      this.values.boundaryCoordinates = [];
    },

    convertCoordinates(data) {
      if (!Array.isArray(data)) {
        console.error("Data is not an array.");
        return [];
      }
      console.log("dataconvert", data);
      return data.map(coordinates => ({
        lat: coordinates[1],
        lng: coordinates[0],
      }));
    },


      async submitHandler(data) {
        if (this.selectedCity) {
          this.updateCity(data, this.selectedCity._id);
        } else {
          this.addCity(data);
        }
      },
      addCity(data) {
        this.performAction = true;
        const api = cities.create;
        data.boundary_coordinates = JSON.parse(data.boundaryCoordinates);
        console.log(api.data);
        api.data = data;
        this.generateAPI(api)
          .then((res) => {
            console.log(res.data);
            this.$formulate.reset("PlanAction");
            this.$bvToast.toast("City added successfully!", {
              title: "Create",
              variant: "success",
              solid: true,
            });
  
            this.$emit("reloadData");
            this.$emit("closeModal");
          })
          .catch((err) => {
            this.$bvToast.toast("City is not added!", {
              title: "Create",
              variant: "danger",
              solid: true,
            });
            console.error(err.response.data);
          })
          .finally(() => {
            this.performAction = false;
          });
      },
      updateCity(data, id) {
        data.boundary_coordinates = JSON.parse(data.boundaryCoordinates);
        this.performAction = true;
        const api = cities.update;
        api.data = data;
        console.log("region new", api.data);
  
        api.id = id;
        this.generateAPI(api)
          .then((res) => {
            console.log("city new", res.data);
  
            this.$bvToast.toast("City updated successfully!", {
              title: "Update",
              variant: "success",
              solid: true,
            });
            this.$emit("reloadData");
            this.$emit("closeModal");
          })
          .catch((err) => {
            this.$bvToast.toast("City is not updated!", {
              title: "Update",
              variant: "danger",
              solid: true,
            });
            console.error(err.response.data);
          })
          .finally(() => {
            this.performAction = false;
          });
      },
      updateSchemaOptions(selectedCity, regionData) {
       
    if (selectedCity && selectedCity.region && selectedCity.region.name) {
      const selectField = this.schema.find((item) => item.index === 2);
 
      // Update options based on API data
      selectField.options = regionData.map((region) => ({
        value: region._id,
        label: region.name,
      }));
console.log("selectField.label",  selectField.options);
      // Set the default selected value based on selectedCity.region.name
      const selectedOption = selectField.options.find((option) => option.label === selectedCity.region.name);
      if (selectedOption) {
          console.log("names",  selectedOption);
       this.values.region = selectedOption.value;
  console.log("this.values.region", this.values.region);
      }
       
    }
  },
    },
    watch: {
        selectedCity(val) {
        if (val) {
          this.values = { ...val };
          console.log("names", this.values.region.name);
  this.updateSchemaOptions(val, this.regionData);
      
       
      

           if (val.boundary_coordinates) {
          this.values.boundaryCoordinates = JSON.stringify(val.boundary_coordinates);
          console.log("boun", this.values.boundaryCoordinates);
          this.values.coordinates = this.convertCoordinates(JSON.parse(this.values.boundaryCoordinates));
          this.values.coord = this.values.coordinates.map(item => ({
            lat: item.lat,
            lng: item.lng
          }));

          this.polylineData = [this.values.coord];
          this.firstcoordinate = this.polylineData[0][0];
          this.center = this.firstcoordinate ? this.firstcoordinate : { lat: 25.190074301612864, lng: 47.1520809379568 },
            console.log("center", this.center);
          // 47.1520809379568
          console.log("asdf", this.values.boundaryCoordinates);

        } else {
          this.values.boundaryCoordinates = ''; // Handle the case when boundary_coordinates is not available
        }
        }

        console.log("length", val.length);
        this.drawing = val.length === 0;
        console.log("sdfadsfasdfadsf", this.drawing);
        this.polylineData = [];
      },

       regionData: {
    handler(val) {
      this.updateSchemaOptions(this.selectedCity, val);
    },
    deep: true,
  },
     
    },
  };
  </script>
  
  <style>
  .payment-duration .formulate-input-group {
    display: flex;
  }
  
  .payment-duration .formulate-input-group-item {
    margin: 8px;
  }
  
  .payment-duration .formulate-input-label {
    margin-top: -6px;
  }
  </style>
  
