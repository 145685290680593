export const cities = {
    create: {
        url:"/city",
        method:"POST",
        data: null,
    },
    get: {
        url: "/city",
        method: "GET",
        data: null,
    },
    delete: {
        url: "/city/",
        method: "DELETE",
        id: null,
        params: null,
    },
    update: {
        url: "city/",
        method: "PUT",
        id: null,
        params: null,
      },

}