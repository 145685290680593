export const regions = {
    create: {
        url:"/region",
        method:"POST",
        data: null,
    },
    get: {
        url: "/region",
        method: "GET",
        data: null,
    },
    delete: {
        url: "/region/",
        method: "DELETE",
        id: null,
        params: null,
    },
    update: {
        url: "region/",
        method: "PUT",
        id: null,
        params: null,
      },





}