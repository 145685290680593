<template>
  <Layout>
    <div class="float-right mb-4">
      <b-button class="bseen-btn" @click="showActionModal">Add</b-button>
    </div>
    <div class="clearfix"></div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="cityData" :fields="fields" responsive="sm" :busy="isBusy" :per-page="perPage"
                :current-page="currentPage" :filter="filter" @filtered="onFiltered">
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <button class="action-btn" @click="deleteCity(row.item)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button class="action-btn" @click="editPlan(row.item)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <!-- <button class="action-btn" @click="addOffer(row.item)">
                      <i class="ri-gift-line"></i>
                    </button> -->
                </template>
                <template #cell(permissions)="row">
                  {{ row.item.permissions.length || 0 }} permissions
                </template>
                <!-- <template #cell(offer_price)="row">
                    <div v-if="row.item.offer_price">
                      {{ row.item.offer_price }}<br />
                      <button class="action-btn" @click="deleteOffer(row.item)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                    <div v-else>N/A</div>
                  </template> -->
                <!-- <template #cell(updatedAt)="row">
                    {{ moment(row.item.updatedAt).format("lll") }}
                  </template> -->
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CityModal :selectedCity="selectedCity" :regionData="regionData" :drawing="drawing" v-on:resetModal="resetModal"
      v-on:reloadData="getAllCities" v-on:closeModal="hideActionModal" />
    <b-modal id="add-offer" title="Offer" hide-footer>
      <h6>Actual price : <strong> {{ formValues.actual_price }}</strong> SAR</h6>

      <FormulateForm :values="formValues" @submit="submitHandler">
        <FormulateInput type="number" name="offer_price" label="Offer price" />

        <FormulateInput type="submit" :label="addingOffer ? 'Adding...' : 'Add offer'" />
      </FormulateForm>
    </b-modal>
  </Layout>
</template>
  
<script>
import Layout from "../../layouts/main";
import { plans } from "@/config/api/plans";
import { cities } from "@/config/api/cities";
import CityModal from "@/components/city/city-modal";
import { regions } from "@/config/api/region";

export default {
  components: { Layout, CityModal },

  data() {
    return {
      cityData: [],
      selectedCity: null,
      isBusy: false,
      totalRows: 0,
      currentPage: 1,
      filter: null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: "name",
      sortDesc: false,
      addingOffer: false,
      formValues: {
        offer_price: 0,
        plan_id: null,
        actual_price: 0,
      },
      fields: [
        { key: "name", sortable: false, label: "City" },
        // { key: "boundary_coordinates", sortable: false },
        { key: "region.name", sortable: false, label: "Region" },
        { key: "rank", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
    //http://localhost:8080/region
  },
  computed: {},
  mounted() {
    this.totalRows = this.cityData.length;
  },
  created() {
    this.getAllCities();
    this.getAllRegions();
  },
  methods: {
    getAllRegions() {
      console.log("error");
      this.isBusy = true;
      const api = regions.get;
      this.generateAPI(api)
        .then((res) => {
          console.log("regions", res);
          this.regionData = res.data.regions;
          this.totalRows = this.regionData.length;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },


    resetModal() {
        console.log("modal");
      // Reset selectedRegion and update drawing to true
      this.selectedCity = null;
      this.drawing = true;
      console.log(this.drawing);
   
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCities() {
      console.log("error");
      this.isBusy = true;
      const api = cities.get;
      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          this.cityData = res.data.citys;
          this.totalRows = this.cityData.length;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    editPlan(data) {
      this.selectedCity = data;
      this.showActionModal();
    },
    showActionModal() {
      this.$bvModal.show("action-Plan");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Plan");
    },
    deleteCity(data) {
      console.log(data.region);
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = cities.delete;
            api.id = data._id;
            //   console.log(da)
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.$bvToast.toast("City deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.getAllCities();
              })
              .catch((err) => {
                this.$bvToast.toast("City is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    //   addOffer(data) {
    //     this.formValues.plan_id = data._id;
    //     this.formValues.offer_price = data.offer_price;
    //      this.formValues.actual_price = data.price;
    //     this.$bvModal.show("add-offer");
    //   },
    async submitHandler(data) {
      const api = plans.addOffer;
      api.data = data;
      this.addingOffer = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast(`Plan offer is added`, {
            title: "Offer",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("add-offer");
          this.planData.find((item) => item._id === data.plan_id).offer_price =
            data.offer_price;
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Plan offer is not added!, ${err.response.data.error}`,
            {
              title: "Offer",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.addingOffer = false;
        });
    },
    deleteOffer(data) {
      this.$bvModal.msgBoxConfirm("Are you sure?").then(() => {
        this.removeOffer(data);
      });
    },
    removeOffer(data) {
      console.log("Remove data", data);
      const api = plans.removeOffer;
      api.id = data._id;

      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast(`Plan offer is removed`, {
            title: "Offer",
            variant: "success",
            solid: true,
          });
          this.getAllPlans();
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Plan offer is not removed ${err.response.data.error}`,
            {
              title: "Offer",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
  },
};
</script>
  