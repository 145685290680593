export const plans = {
  create: {
    url: "plans/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "plans/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  get: {
    url: "plans/get",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "plans/delete/",
    method: "DELETE",
    id: null,
  },
  addOffer: {
    url: "plan/addOffer",
    method: "POST",
    data: null,
  },
  removeOffer: {
    url: "plan/removeOffer/",
    method: "GET",
    id: null,
  },
  getActivePlans: {
    url: "plans/getActivePlans",
    method: "GET",
    id: null,
  },
};
